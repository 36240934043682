import axios from "axios";
import { URL_BASE } from "../../constant/url";
import cookie from "js-cookie";
import {
  AUTH_LOGIN,
  AUTH_REFRESH_TOKEN,
  SUCCESSFUL,
} from "../../constant/types";

export async function login(payload) {
  let token = cookie.get("token");
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: "Bearer " + token,
  };
  axios.defaults.headers = headers;

  let url = URL_BASE + `auth/signin?timestamp=${new Date().getTime()}`;
  try {
    const res = await axios.post(url, payload);
    if (res.data.status === SUCCESSFUL)
      return Promise.resolve({
        type: AUTH_LOGIN,
        payload: res.data.response.user,
      });
      else return Promise.resolve({ type: "ERROR", msj: res.data.response.msj });
  } catch (err) {
    return Promise.resolve({ type: "ERROR" });
  }
}

export async function rememberLogin(token) {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: "Bearer " + token,
  };
  axios.defaults.headers = headers;

  let url = URL_BASE + `auth/signin_me?timestamp=${new Date().getTime()}`;
  try {
    const res = await axios.get(url);
    if (res.data.status === SUCCESSFUL)
      return Promise.resolve({
        type: AUTH_REFRESH_TOKEN,
        payload: res.data.response.user,
      });
    else return Promise.resolve({ type: "ERROR", msj: res.data.response.msj });
  } catch (err) {
    return Promise.resolve({ type: "ERROR" });
  }
}
