import React, { useState, useRef, useEffect } from "react";
import clsx from "clsx";
import { Link as RouterLink, useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Drawer from "@material-ui/core/Drawer";
import Link from "@material-ui/core/Link";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

// icons
import AccountCircle from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import MenuIcon from "@material-ui/icons/Menu";

import { useSelector, useDispatch } from "react-redux";
import cookie from "js-cookie";

import ScrollToTop from "./ScrollToTop";
import { AUTH_LOGOUT } from "../constant/types";

const drawerWidth = "100%";
const drawerWidthDesktop = 140;

const useStyles = makeStyles((theme) => ({
  barraHeader: {
    width: "100%",
    height: "80px",
    display: "flex",
    justifyContent: "flex-end",
    backgroundColor: "#2D3142",
    boxShadow: "0px 5px 5px #00000029",
    [theme.breakpoints.down("sm")]: {
      height: "64px",
    },
  },
  toolbar: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: "100%",
    padding: 0,
    margin: "auto",
    opacity: 1,
  },
  containerImgFirma: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    position: "relative"
  },
  imgFirma: {
    height: 45,
    [theme.breakpoints.up("md")]: {
      height: 60,
    },
  },

  botonera: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
    bottom: 0,
    justifyContent: "space-between",
    // maxWidth: 1200,
    marginInline: "auto",
  },
  botones: {
    width: "100%",
    margin: "auto",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    position: "relative",
    alignItems: "center",
  },

  link: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
  // Drawer
  hide: {
    display: "none",
  },
  drawer: {
    zIndex: 999,
    width: drawerWidth,
    [theme.breakpoints.up("md")]: {
      width: drawerWidthDesktop,
    },
    flexShrink: 0,
    whiteSpace: "nowrap",
    backgroundColor: "#DAD1C9",
  },
  drawerOpen: {
    height: "calc(100vh - 64px)",
    position: "fixed",
    maxHeight: "100vh",
    marginTop: 64,
    backgroundColor: "rgba(0,0,0,0.5)",
    width: drawerWidth,
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      marginTop: 80,
      width: drawerWidthDesktop,
      height: "calc(100vh - 80px)",
    },
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    height: "calc(100vh - 64px)",
    position: "fixed",
    maxHeight: "100vh",
    marginTop: 64,
    backgroundColor: "#DAD1C9",
    // transition: theme.transitions.create("width", {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen,
    // }),
    overflowX: "hidden",
    width: 0,
    [theme.breakpoints.up("md")]: {
      marginTop: 80,
      height: "calc(100vh - 80px)",
      width: theme.spacing(7) + 1,
    },
  },
  paperOpen: {
    height: "calc(100vh - 64px)",
    position: "absolute",
    maxHeight: "100vh",
    backgroundColor: "#DAD1C9",
    width: drawerWidthDesktop,
    [theme.breakpoints.up("md")]: {
      height: "calc(100vh - 80px)",
      width: drawerWidthDesktop,
    },
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  paperClose: {
    height: "calc(100vh - 64px)",
    position: "absolute",
    maxHeight: "100vh",
    backgroundColor: "#DAD1C9",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: 0,
    [theme.breakpoints.up("md")]: {
      height: "calc(100vh - 80px)",
      width: theme.spacing(7) + 1,
    },
  },
  listItemsOpen: {
    height: "calc(100vh - 138px)",
    overflowY: "scroll",
    padding: 0,
    paddingTop: 10,
    "& span": {
      letterSpacing: 1.76,
      color: "#1F2533",
      fontFamily: "Europa-Regular",
    },
  },
  listItemsClose: {
    height: "calc(100vh - 138px)",
    overflowY: "scroll",
    padding: 0,
    paddingTop: 10,
    overflowX: "hidden",
    width: 0,
    [theme.breakpoints.up("md")]: {
      width: theme.spacing(9) + 1,
    },
  },
  actionDraw: {
    height: 40,
    position: "absolute",
    bottom: 0,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]:{
      display: "none"
    }
  },
  buttonActionDraw: {
    color: "#1F2533 !important",
    "&:hover": {
      background: "transparent",
    },
    "& span": {
      letterSpacing: 1.76,
      fontSize: 14,
      color: "#1F2533",
      fontFamily: "Europa-Regular",
    },
  },
  // Main
  main: {
    flexGrow: 1,
    maxHeight: "calc(100vh - 64px)",
    height: "100%",
    overflow:"scroll",
    paddingTop: 64,
    padding:"64px 15px 0px",
    backgroundColor: "#EFEDE9",
    [theme.breakpoints.up("md")]: {
      maxHeight: "calc(100vh - 80px)",
      paddingTop: 80,
      paddingLeft: 135
    },
  },
}));

const HeaderHome = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const loggedIn = useSelector((store) => store.AuthReducer.loggedIn);
  const user = useSelector((store) => store.AuthReducer.user);

  const refToolbar = useRef();
  const [open, setOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const matches = useMediaQuery("(min-width:960px)");
  useEffect(() => {
    if (matches) {
      setOpen(true);
    }
  }, [matches]);
  const handleLogout = (e) => {
    e.preventDefault();
    setAnchorEl(null);
    cookie.remove("token");
    dispatch({ type: AUTH_LOGOUT });
  };
  const handleMenu = (event) => {
    setOpenMenu(true);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpenMenu(false);
    setAnchorEl(null);
  };

  const handleDrawerClose = () => {
    if (matches === false) setOpen(false);
  };
  const handleDrawerToggle = () => {
    if (matches === false) setOpen(!open);
  };

  return (
    <React.Fragment>
      <AppBar position="fixed" className={classes.barraHeader}>
        <Toolbar id="toolbar" ref={refToolbar} className={classes.toolbar}>
          <Grid
            container
            alignItems="center"
            justifyContent={"space-between"}
            className={classes.botonera}
          >
            <nav className={classes.botones}>
              <div className={classes.containerImgFirma}>
                {!matches && (
                  <IconButton disableRipple onClick={handleDrawerToggle}>
                    <MenuIcon style={{ color: "white" }} />
                  </IconButton>
                )}
                <Link
                  className={classes.link}
                  to={{ pathname: "/" }}
                  component={RouterLink}
                >
                  <img
                    className={classes.imgFirma}
                    src={require("../assets/header/firma.png").default}
                    alt={"firma Susana"}
                  />
                </Link>
              </div>

              {loggedIn && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <IconButton
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                  >
                    <AccountCircle />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={openMenu}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleClose}>
                      <PersonOutlineIcon /> {"  "}
                      {user.name}
                    </MenuItem>
                    <MenuItem onClick={handleLogout}>
                      <ExitToAppIcon />
                      {"  "} Salir
                    </MenuItem>
                  </Menu>
                </div>
              )}
            </nav>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.paperOpen]: open,
            [classes.paperClose]: !open,
          }),
        }}
        onClick={handleDrawerToggle}
        // onMouseEnter={handleDrawerOpen}
        // onMouseLeave={handleDrawerClose}
      >
        <List
          className={clsx({
            [classes.listItemsOpen]: open,
            [classes.listItemsClose]: !open,
          })}
        >
          <ListItem
            button
            onClick={() => {
              handleDrawerClose();
              history.push("/wines");
            }}
          >
            <ListItemText primary="Vinos" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              handleDrawerClose();
              history.push("/lines");
            }}
          >
            <ListItemText primary="Marcas" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              handleDrawerClose();
              history.push("/sub-lines");
            }}
          >
            <ListItemText primary="SubMarcas" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              handleDrawerClose();
              history.push("/news");
            }}
          >
            <ListItemText primary="Noticias" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              handleDrawerClose();
              history.push("/categories");
            }}
          >
            <ListItemText primary="Categorias" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              handleDrawerClose();
              history.push("/files");
            }}
          >
            <ListItemText primary="Archivos" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              handleDrawerClose();
              history.push("/newsletter");
            }}
          >
            <ListItemText primary="Newsletter" />
          </ListItem>
        </List>

        <div className={classes.actionDraw}>
          <Divider />
          <IconButton
            disableRipple
            onClick={handleDrawerToggle}
            className={classes.buttonActionDraw}
          >
            {open === true ? (
              <React.Fragment>
                <DoubleArrowIcon
                  style={{
                    color: "#1F2533",
                    transform: "rotate(180deg)",
                    height: 15,
                  }}
                />{" "}
                Cerrar
              </React.Fragment>
            ) : (
              <DoubleArrowIcon />
            )}
          </IconButton>
        </div>
      </Drawer>
      <ScrollToTop>
        <main className={classes.main}>{props.children}</main>
      </ScrollToTop>
    </React.Fragment>
  );
};

export default HeaderHome;
