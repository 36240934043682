// RESPONSE API
export const SUCCESSFUL = "SUCCESSFUL";
export const ERROR = "ERROR";

// AUTHORIZATION
export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_REFRESH_TOKEN = "AUTH_REFRESH_TOKEN";

// CREATE WINES
export const CREATE_SET_LANG = "CREATE_SET_LANG";
export const CREATE_SELECT_LANG = "CREATE_SELECT_LANG";
export const CREATE_SET_LINES = "CREATE_SET_LINES";
export const CREATE_SELECT_LINE = "CREATE_SELECT_LINE";
export const CREATE_SET_LABELS = "CREATE_SET_LABELS";
export const CREATE_RESET_LABEL = "CREATE_RESET_LABEL";
export const CREATE_SELECT_LABEL = "CREATE_SELECT_LABEL";
export const CREATE_SET_ORDERS = "CREATE_SET_ORDERS";
export const CREATE_SELECT_ORDER = "CREATE_SELECT_ORDER";
export const CREATE_SELECT_STATE = "CREATE_SELECT_STATE";
export const CREATE_SET_BOTTLE_SHOOT_PATH = "CREATE_SET_BOTTLE_SHOOT_PATH";
export const CREATE_SET_DESCRIPTIONS_PRIMARY =
  "CREATE_SET_DESCRIPTIONS_PRIMARY";
export const CREATE_SET_DESCRIPTIONS_SECUNDARY =
  "CREATE_SET_DESCRIPTIONS_SECUNDARY";
export const CREATE_REMOVE_DESCRIPTION = "CREATE_REMOVE_DESCRIPTION";
export const CREATE_SET_TECHNICAL_SHEET = "CREATE_SET_TECHNICAL_SHEET";
export const CREATE_REMOVE_TECHNICAL_SHEET = "CREATE_REMOVE_TECHNICAL_SHEET";
export const CREATE_SET_BOTTLE_SHOOT = "CREATE_SET_BOTTLE_SHOOT";
export const CREATE_SET_COUNTRIES = "CREATE_SET_COUNTRIES";
export const CREATE_SET_STORES = "CREATE_SET_STORES";
export const CREATE_SELECT_SLUG = "CREATE_SELECT_SLUG";
// LIST WINES
export const LIST_SET_LANG = "LIST_SET_LANG";
export const LIST_SELECT_LANG = "LIST_SELECT_LANG";
export const LIST_SET_LINES = "LIST_SET_LINES";
export const LIST_SELECT_LINE = "LIST_SELECT_LINE";
export const LIST_SET_WINES = "LIST_SET_WINES";
export const LIST_DELETE_WINE = "LIST_DELETE_WINE";

// LIST LINES
export const LIST_LINES_SET_LINES = "LIST_LINES_SET_LINES";
export const CREATE_LINE_SET_STORES = "CREATE_LINE_SET_STORES";
export const CREATE_LINE_SET_COUNTRIES = "CREATE_LINE_SET_COUNTRIES";
export const CREATE_LINE_SET_CHECKED = "CREATE_LINE_SET_CHECKED";

// EDIT WINES
export const EDIT_SET_LANG = "EDIT_SET_LANG";
export const EDIT_SELECT_LANG = "EDIT_SELECT_LANG";
export const EDIT_SET_LINES = "EDIT_SET_LINES";
export const EDIT_SELECT_STATE = "EDIT_SELECT_STATE";
export const EDIT_NEWS_SET_DATE = "EDIT_NEWS_SET_DATE";
export const EDIT_SELECT_LINE = "EDIT_SELECT_LINE";
export const EDIT_SET_LABELS = "EDIT_SET_LABELS";
export const EDIT_SET_ALL_TECHNICAL_SHEET = "EDIT_SET_ALL_TECHNICAL_SHEET";
export const EDIT_RESET_LABEL = "EDIT_RESET_LABEL";
export const EDIT_SELECT_LABEL = "EDIT_SELECT_LABEL";
export const EDIT_SET_ORDERS = "EDIT_SET_ORDERS";
export const EDIT_SELECT_ORDER = "EDIT_SELECT_ORDER";
export const EDIT_SET_DESCRIPTIONS_PRIMARY = "EDIT_SET_DESCRIPTIONS_PRIMARY";
export const EDIT_SET_DESCRIPTIONS_SECUNDARY =
  "EDIT_SET_DESCRIPTIONS_SECUNDARY";
export const EDIT_REMOVE_DESCRIPTION = "EDIT_REMOVE_DESCRIPTION";
export const EDIT_SET_TECHNICAL_SHEET = "EDIT_SET_TECHNICAL_SHEET";
export const EDIT_REMOVE_TECHNICAL_SHEET = "EDIT_REMOVE_TECHNICAL_SHEET";
export const EDIT_SET_BOTTLE_SHOOT = "EDIT_SET_BOTTLE_SHOOT";
export const EDIT_SET_COUNTRIES = "EDIT_SET_COUNTRIES";
export const EDIT_SET_STORES = "EDIT_SET_STORES";
export const EDIT_SET_WINE = "EDIT_SET_WINE";
export const EDIT_SELECT_SLUG = "EDIT_SELECT_SLUG";

// LIST LABELS
export const LIST_LABELS_SET_LINES = "LIST_LABELS_SET_LINES";
export const LIST_LABELS_SET_LABELS = "LIST_LABELS_SET_LABELS";
export const LIST_LABELS_SET_LANGUAGES = "LIST_LABELS_SET_LANGUAGES";
export const LIST_LABELS_SELECT_LINE = "LIST_LABELS_SELECT_LINE";
export const LIST_LABELS_SET_LANG_LABELS = "LIST_LABELS_SET_LANG_LABELS";
export const LIST_LABELS_SELECT_ORDER = "LIST_LABELS_SELECT_ORDER";
export const LIST_LABELS_SELECT_LANG = "LIST_SELECT_LANG";
export const LIST_LABELS_DELETE = "LIST_LABELS_DELETE";
export const CREATE_LABEL_SET_CHECKED = "CREATE_LABEL_SET_CHECKED";
export const LIST_LABELS_RESET = "LIST_LABELS_RESET";

// LIST NEWS
export const LIST_NEWS_SET_LANGUAGES = "LIST_NEWS_SET_LANGUAGES";
export const LIST_NEWS_SELECT_ORDER = "LIST_NEWS_SELECT_ORDER";
export const LIST_NEWS_SELECT_LANG = "LIST_NEWS_SELECT_LANG";
export const LIST_NEWS_SET_NEWS = "LIST_NEWS_SET_NEWS";
export const LIST_NEWS_DELETE = "LIST_NEWS_DELETE";

//LIST CATEGORIES
export const LIST_SET_CATEGORIES = "LIST_SET_CATEGORIES";
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const LIST_CATEGORIES_SELECT_LANG = "LIST_CATEGORIES_SELECT_LANG";
export const LIST_CATEGORIES_SET_LANGUAGES = "LIST_CATEGORIES_SET_LANGUAGES";
export const LIST_CATEGORIES_SET_TITLES = "LIST_CATEGORIES_SET_TITLES";
export const LIST_CATEGORIES_DELETE = "LIST_CATEGORIES_DELETE";
export const CREATE_CATEGORY_SET_CHECKED = "CREATE_CATEGORY_SET_CHECKED";
export const LIST_CATEGORIES_RESET = "LIST_CATEGORIES_RESET";

//CREATE NEWS
export const CREATE_NEWS_SELECT_STATE = "CREATE_NEWS_SELECT_STATE";
export const CREATE_NEWS_REMOVE_DESCRIPTION = "CREATE_REMOVE_DESCRIPTION";
export const CREATE_NEWS_SET_LANG = "CREATE_NEWS_SET_LANG";
export const CREATE_NEWS_SET_DESCRIPTIONS = "CREATE_NEWS_SET_DESCRIPTIONS";
export const CREATE_NEWS_SET_SLUG = "CREATE_NEWS_SET_SLUG";
export const CREATE_NEWS_SET_DESCRIPTIONS_SECUNDARY =
  "CREATE_NEWS_SET_DESCRIPTIONS_SECUNDARY";
export const CREATE_NEWS_SET_DATE = "CREATE_NEWS_SET_DATE";
export const CREATE_NEWS_SELECT_LANG = "CREATE_NEWS_SELECT_LANG";
export const CREATE_NEWS_SET_IMAGE = "CREATE_NEWS_SET_IMAGE";
export const CREATE_NEWS_SET_CATEGORIES = "CREATE_NEWS_SET_CATEGORIES";
export const CREATE_NEWS_ADD_CATEGORY = "CREATE_NEWS_ADD_CATEGORY";
export const CREATE_NEWS_REMOVE_CATEGORY = "CREATE_NEWS_REMOVE_CATEGORY";

//EDIT NEWS
export const EDIT_NEWS_REMOVE_DESCRIPTION = "EDIT_REMOVE_DESCRIPTION";
export const EDIT_NEWS_SET_LANG = "EDIT_NEWS_SET_LANG";
export const EDIT_NEWS_SET_DESCRIPTIONS = "EDIT_NEWS_SET_DESCRIPTIONS";
export const EDIT_NEWS_SELECT_STATE = "EDIT_NEWS_SELECT_STATE";
export const EDIT_NEWS_SET_DESCRIPTIONS_SECUNDARY =
  "EDIT_NEWS_SET_DESCRIPTIONS_SECUNDARY";
export const EDIT_NEWS_SET_NEWS = "EDIT_NEWS_SET_NEWS";
export const EDIT_NEWS_SELECT_LANG = "EDIT_NEWS_SELECT_LANG";
export const EDIT_NEWS_SET_IMAGE = "EDIT_NEWS_SET_IMAGE";
export const EDIT_NEWS_SET_CATEGORIES = "EDIT_NEWS_SET_CATEGORIES";
export const EDIT_NEWS_ADD_CATEGORY = "EDIT_NEWS_ADD_CATEGORY";
export const EDIT_NEWS_REMOVE_CATEGORY = "EDIT_NEWS_REMOVE_CATEGORY";

// LIST TOURISM
export const LIST_PDFS = "LIST_PDFS";
export const LIST_PDFS_SELECT_LANG = "LIST_PDFS_SELECT_LANG";
export const LIST_PDFS_SET_LANGUAGES = "LIST_PDFS_SET_LANGUAGES";

// ESTADOS
export const MODIFY = "MODIFY";
export const NOT_MODIFY = "NOT_MODIFY";
export const DELETE = "DELETE";

// NEWSLETTERS
export const LIST_SET_NEWSLETTERS = "LIST_SET_NEWSLETTERS";
