import {
  EDIT_NEWS_ADD_CATEGORY,
  EDIT_NEWS_REMOVE_CATEGORY,
  EDIT_NEWS_REMOVE_DESCRIPTION,
  EDIT_NEWS_SELECT_LANG,
  EDIT_NEWS_SELECT_STATE,
  EDIT_NEWS_SET_CATEGORIES,
  EDIT_NEWS_SET_DATE,
  EDIT_NEWS_SET_DESCRIPTIONS,
  EDIT_NEWS_SET_IMAGE,
  EDIT_NEWS_SET_LANG,
  EDIT_NEWS_SET_NEWS,
  ERROR,
} from "../constant/types";

const initialState = {
  lang: [],
  news: [],
  date: null,
  selectLang: null,
  descriptions: [], //{title,description,language,uuid}
  image: null,
  loading: true,
  categories: [],
  selectCategories: [],
  selectedState: null,
};

export default function editNews(state = initialState, action) {
  switch (action.type) {
    case EDIT_NEWS_SET_NEWS:
      return {
        ...state,
        news: action.payload,
        descriptions: action.payload.title.concat(
          action.payload.extract.concat(action.payload.paragraphs)
        ),
        selectCategories: setIdsCategories(action.payload.selectCategories),
      };
    case EDIT_NEWS_SELECT_STATE:
      return {
        ...state,
        selectedState: action.payload,
      };
    case EDIT_NEWS_SET_LANG:
      return {
        ...state,
        lang: action.payload,
      };
    case EDIT_NEWS_SET_DATE:
      return {
        ...state,
        date: action.payload,
      };
    case EDIT_NEWS_SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case EDIT_NEWS_SELECT_LANG:
      return {
        ...state,
        selectLang: action.payload,
      };
    case EDIT_NEWS_SET_DESCRIPTIONS:
      return {
        ...state,
        descriptions: setDescription(state.descriptions, action.payload),
      };
    case EDIT_NEWS_REMOVE_DESCRIPTION:
      return {
        ...state,
        descriptions: removeDescription(state.descriptions, action.payload),
      };
    case EDIT_NEWS_SET_IMAGE:
      return {
        ...state,
        image: action.payload,
      };
    case EDIT_NEWS_ADD_CATEGORY:
      return {
        ...state,
        selectCategories: addCategories(state.selectCategories, action.payload),
      };
    case EDIT_NEWS_REMOVE_CATEGORY:
      return {
        ...state,
        selectCategories: removeCategories(
          state.selectCategories,
          action.payload
        ),
      };
    case ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
}

const addCategories = (selectCategories, category_id) => {
  let newSelectCategories = selectCategories.concat([category_id]);
  return newSelectCategories;
};

const removeCategories = (selectCategories, category_id) => {
  let newSelectCategories = selectCategories.filter(
    (elem) => elem !== category_id
  );
  return newSelectCategories;
};

const setIdsCategories = (selectCategories) => {
  let idCategories = [];
  selectCategories.map((data, index) => idCategories.push(data.category_id));
  return idCategories;
};

const setDescription = (descriptions, payload) => {
  let search = descriptions.find((elem) => elem.uuid === payload.uuid);
  if (!search) {
    let newObject = {};
    Object.defineProperty(newObject, payload.key, {
      value: payload.value,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "lang", {
      value: payload.lang.code,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "uuid", {
      value: payload.uuid,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "type", {
      value: payload.type,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    if (payload.type === "paragraph")
      Object.defineProperty(newObject, "order", {
        value: payload.order,
        writable: true,
        configurable: true,
        enumerable: true,
      });
    if (payload.type === "slug")
      Object.defineProperty(newObject, "modify", {
        value: payload.modify,
        writable: true,
        configurable: true,
        enumerable: true,
      });
    return descriptions.concat([newObject]);
  } else {
    let newObject = descriptions.find((elem) => elem.uuid === payload.uuid);
    let newDescriptions = descriptions.filter(
      (elem) => elem.uuid !== payload.uuid
    );
    if (!payload.value) {
      return newDescriptions;
    }
    Object.defineProperty(newObject, payload.key, {
      value: payload.value,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "lang", {
      value: payload.lang.code,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "uuid", {
      value: payload.uuid,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "type", {
      value: payload.type,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    if (payload.type === "paragraph")
      Object.defineProperty(newObject, "order", {
        value: payload.order,
        writable: true,
        configurable: true,
        enumerable: true,
      });
    if (payload.type === "slug")
      Object.defineProperty(newObject, "modify", {
        value: payload.modify,
        writable: true,
        configurable: true,
        enumerable: true,
      });
    newDescriptions.push(newObject);
    return newDescriptions;
  }
};

// const removeDescription = (descriptions, payload) => {
//   console.log(descriptions, payload);
//   let newDescriptions = descriptions.filter(
//     (elem) => elem.uuid !== payload.uuid
//   );
//   console.log("NEW ANTES DESC", newDescriptions);
//   newDescriptions.map(
//     (description) =>
//       (description.order =
//         description.type === "paragraph" && description.lang === payload.lang
//           ? description.order >= payload.order
//             ? description.order - 1
//             : description.order
//           : description.order)
//   );
//   console.log("NEW DESC", newDescriptions);
//   return newDescriptions;
// };

const removeDescription = (descriptions, payload) => {
  const newDescriptions = descriptions.filter((el) => el.uuid !== payload.uuid);
  for (const description of newDescriptions) {
    if (
      description.type === "paragraph" &&
      description.lang === payload.lang &&
      description.order > payload.order
    ) {
      description.order -= 1;
    }
  }
  return newDescriptions;
};
