import {
  LIST_LINES_SET_LINES,
  CREATE_LINE_SET_COUNTRIES,
  CREATE_LINE_SET_STORES,
  CREATE_LINE_SET_CHECKED,
  ERROR,
} from "../constant/types";

const initialState = {
  lines: [],
  loading: true,
  countries: [],
  stores: [],
  checked: false,
  line: null,
};

export default function listWines(state = initialState, action) {
  switch (action.type) {
    case LIST_LINES_SET_LINES:
      return {
        ...state,
        lines: action.payload,
      };
    case CREATE_LINE_SET_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      };
    case CREATE_LINE_SET_STORES:
      return {
        ...state,
        stores: setStores(state.stores, action.payload),
      };
    case CREATE_LINE_SET_CHECKED:
      return {
        ...state,
        checked: action.payload.checked,
        line: state.lines.find(
          (elem) => elem.line_id === action.payload.line_id
        ),
        stores:[]
      };
    case ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
}
const setStores = (stores, payload) => {
  let search = stores.find((elem) => elem.code === payload.code);
  if (!search) {
    let newObject = {};
    Object.defineProperty(newObject, "link", {
      value: payload.link,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "code", {
      value: payload.code,
      writable: true,
      configurable: true,
      enumerable: true,
    });

    return stores.concat([newObject]);
  } else {
    let newObject = stores.find((elem) => elem.code === payload.code);
    let newStores = stores.filter((elem) => elem.code !== payload.code);
    if (!payload.link) {
      return newStores;
    } else {
      Object.defineProperty(newObject, "link", {
        value: payload.link,
        writable: true,
        configurable: true,
        enumerable: true,
      });
      Object.defineProperty(newObject, "code", {
        value: payload.code,
        writable: true,
        configurable: true,
        enumerable: true,
      });
      newStores.push(newObject);
      return newStores;
    }
  }
};
