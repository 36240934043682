import {
  LIST_NEWS_SET_LANGUAGES,
  LIST_NEWS_SET_NEWS,
  ERROR,
  LIST_NEWS_SELECT_ORDER,
  LIST_NEWS_SELECT_LANG,
  LIST_NEWS_DELETE,
} from "../constant/types";

const initialState = {
  news: [],
  filterNews: null,
  selectLang: null,
  languages: [],
  langNews: [],
  selectOrder: null,
  loading: true,
};

export default function listNews(state = initialState, action) {
  switch (action.type) {
    case LIST_NEWS_SET_LANGUAGES:
      return {
        ...state,
        languages: action.payload,
      };
    case LIST_NEWS_SELECT_LANG:
      return {
        ...state,
        selectLang: action.payload,
      };
    case LIST_NEWS_SET_NEWS:
      return {
        ...state,
        news: action.payload,
      };
    case LIST_NEWS_DELETE:
      return {
        ...state,
        news: state.news.filter((elem) => elem.notice_id !== action.payload),
      };
    case LIST_NEWS_SELECT_ORDER:
      return {
        ...state,
        selectOrder: action.payload,
      };
    case ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
}
