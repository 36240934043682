import {
  LIST_SET_CATEGORIES,
  ERROR,
  LIST_CATEGORIES_SELECT_LANG,
  LIST_CATEGORIES_SET_LANGUAGES,
  LIST_CATEGORIES_SET_TITLES,
  CREATE_CATEGORY_SET_CHECKED,
  LIST_SET_NEWSLETTERS,
  LIST_CATEGORIES_DELETE,
  LIST_CATEGORIES_RESET,
} from "../constant/types";

const initialState = {
  categories: [],
  category: null,
  selectLang: null,
  languages: [],
  langCategories: [],
  newsletters: [],
};
export default function listCategories(state = initialState, action) {
  switch (action.type) {
    case LIST_SET_NEWSLETTERS:
      return {
        ...state,
        newsletters: action.payload,
      };
    case LIST_CATEGORIES_SET_LANGUAGES:
      return {
        ...state,
        languages: action.payload,
      };
    case LIST_CATEGORIES_SELECT_LANG:
      return {
        ...state,
        selectLang: action.payload,
      };
    case CREATE_CATEGORY_SET_CHECKED:
      return {
        ...state,
        checked: action.payload.checked,
        category: state.categories.filter(
          (elem) => elem.category_id === action.payload.category_id
        ),
      };
    case LIST_SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case LIST_CATEGORIES_DELETE:
      return {
        ...state,
        categories: state.categories.filter(
          (elem) => elem.category_id !== action.payload
        ),
      };
    case LIST_CATEGORIES_SET_TITLES:
      return {
        ...state,
        langCategories: setCategories(state.langCategories, action.payload),
      };
     case LIST_CATEGORIES_RESET:
      return {
        ...state,
        checked:false,
        category: null,
        langCategories: [],
      };
    case ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
}

const setCategories = (langCategories, payload) => {
  let search = langCategories.find((elem) => elem.uuid === payload.uuid);
  if (!search) {
    let newObject = {};
    Object.defineProperty(newObject, "title", {
      value: payload.value,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "lang", {
      value: payload.lang,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "uuid", {
      value: payload.uuid,
      writable: true,
      configurable: true,
      enumerable: true,
    });

    return langCategories.concat([newObject]);
  } else {
    let newObject = langCategories.find((elem) => elem.uuid === payload.uuid);
    let newDescriptions = langCategories.filter(
      (elem) => elem.uuid !== payload.uuid
    );
    Object.defineProperty(newObject, "title", {
      value: payload.value,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "lang", {
      value: payload.lang,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "uuid", {
      value: payload.uuid,
      writable: true,
      configurable: true,
      enumerable: true,
    });

    newDescriptions.push(newObject);
    return newDescriptions;
  }
};
