import {
  EDIT_REMOVE_DESCRIPTION,
  EDIT_REMOVE_TECHNICAL_SHEET,
  EDIT_RESET_LABEL,
  EDIT_SELECT_LABEL,
  EDIT_SELECT_LANG,
  EDIT_SELECT_LINE,
  EDIT_SELECT_ORDER,
  EDIT_SET_ALL_TECHNICAL_SHEET,
  EDIT_SET_BOTTLE_SHOOT,
  EDIT_SET_COUNTRIES,
  EDIT_SET_DESCRIPTIONS_PRIMARY,
  EDIT_SET_DESCRIPTIONS_SECUNDARY,
  EDIT_SET_LABELS,
  EDIT_SET_LANG,
  EDIT_SET_LINES,
  EDIT_SET_STORES,
  EDIT_SET_TECHNICAL_SHEET,
  EDIT_SET_WINE,
  EDIT_SELECT_SLUG,
  ERROR,
} from "../constant/types";

const initialState = {
  lang: [],
  selectLang: null,
  wine: null,
  lines: [],
  selectLine: null,
  labels: [],
  filterLabels: [],
  selectLabel: null,
  selectEditLabel: null,
  descriptions: [], //{title,description,language,uuid}
  details: [],
  selectOrder: null, //{order,language}
  slugs: [],
  technicalSheets: [],
  bottleShoot: null,
  loading: true,
  countries: [],
  stores: [],
};

export default function editWines(state = initialState, action) {
  switch (action.type) {
    case EDIT_SET_WINE:
      return {
        ...state,
        wine: action.payload.wine,
        descriptions: action.payload.descriptions,
        stores: action.payload.stores,
        selectOrder: action.payload.wine.wine_order,
        technicalSheets: action.payload.technicalSheets,
      };
    case EDIT_SET_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      };
    case EDIT_SET_LANG:
      return {
        ...state,
        lang: action.payload,
      };
    case EDIT_SELECT_LANG:
      return {
        ...state,
        selectLang: action.payload,
      };
    case EDIT_SET_LINES:
      return {
        ...state,
        lines: action.payload,
      };
    case EDIT_SELECT_LINE:
      return {
        ...state,
        selectLine: state.lines.find((elem) => elem.line_id === action.payload),
        filterLabels: state.labels.filter(
          (elem) =>
            elem.line_id === action.payload &&
            elem.language_id === state.selectLang
        ),
      };
    case EDIT_SET_LABELS:
      return {
        ...state,
        labels: action.payload,
      };
    case EDIT_RESET_LABEL:
      return {
        ...state,
        selectLabel: null,
      };
    case EDIT_SELECT_LABEL:
      return {
        ...state,
        selectOrder: action.payload.resetOrder ? null : state.selectOrder,
        selectLabel: state.labels.filter(
          (elem) => elem.label_id === action.payload.label_id
        ),
      };

    case EDIT_SELECT_ORDER:
      return {
        ...state,
        selectOrder: action.payload,
      };
    case EDIT_SELECT_SLUG:
      return {
        ...state,
        slugs: setSlugs(state.slugs, action.payload),
      };
    case EDIT_SET_DESCRIPTIONS_PRIMARY:
      return {
        ...state,
        descriptions: setDescription(
          state.descriptions,
          action.payload,
          "primary"
        ),
      };
    case EDIT_SET_STORES:
      return {
        ...state,
        stores: setStores(state.stores, action.payload),
      };
    case EDIT_SET_DESCRIPTIONS_SECUNDARY:
      return {
        ...state,
        descriptions: setDescription(
          state.descriptions,
          action.payload,
          "secundary"
        ),
      };
    case EDIT_REMOVE_DESCRIPTION:
      return {
        ...state,
        descriptions: state.descriptions.filter(
          (elem) => elem.uuid !== action.payload
        ),
      };
    case EDIT_SET_TECHNICAL_SHEET:
      return {
        ...state,
        technicalSheets: setFile(state.technicalSheets, action.payload),
      };
    case EDIT_SET_ALL_TECHNICAL_SHEET:
      return {
        ...state,
        technicalSheets: action.payload,
      };
    case EDIT_REMOVE_TECHNICAL_SHEET:
      return {
        ...state,
        // technicalSheets: state.technicalSheets.filter(
        //   (elem) => elem.uuid !== action.payload
        // ),
        technicalSheets: setFile(state.technicalSheets, action.payload),
      };
    case EDIT_SET_BOTTLE_SHOOT:
      return {
        ...state,
        bottleShoot: action.payload.file,
        stateImage: action.payload.state,
      };
    case ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
}

const setDescription = (descriptions, payload, type) => {
  let search = descriptions.find((elem) => elem.uuid === payload.uuid);
  if (!search) {
    let newObject = {};
    Object.defineProperty(newObject, payload.key, {
      value: payload.value,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "language", {
      value: payload.lang.code,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "uuid", {
      value: payload.uuid,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "type", {
      value: type,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    if (type === "secundary")
      Object.defineProperty(newObject, "order", {
        value: payload.order,
        writable: true,
        configurable: true,
        enumerable: true,
      });
    if (type === "primary") {
      console.log("ACA ENTRA AL NEW OBJECT  SLUG");
      Object.defineProperty(newObject, "slug", {
        value: payload.slug,
        writable: true,
        configurable: true,
        enumerable: true,
      });
    }
    return descriptions.concat([newObject]);
  } else {
    let newObject = descriptions.find((elem) => elem.uuid === payload.uuid);
    let newDescriptions = descriptions.filter(
      (elem) => elem.uuid !== payload.uuid
    );
    Object.defineProperty(newObject, payload.key, {
      value: payload.value,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "language", {
      value: payload.lang.code,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "uuid", {
      value: payload.uuid,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "type", {
      value: type,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    if (type === "secundary")
      Object.defineProperty(newObject, "order", {
        value: payload.order,
        writable: true,
        configurable: true,
        enumerable: true,
      });
    if (type === "primary") {
      console.log("ACA ENTRA AL NEW OBJECT  SLUG");
      Object.defineProperty(newObject, "slug", {
        value: payload.slug,
        writable: true,
        configurable: true,
        enumerable: true,
      });
    }
    newDescriptions.push(newObject);
    return newDescriptions;
  }
};

const setFile = (descriptions, payload) => {
  let search = descriptions.find((elem) => elem.uuid === payload.uuid);
  if (!search) {
    let newObject = {};
    Object.defineProperty(newObject, payload.key, {
      value: payload.value,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "lang", {
      value: payload.lang,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "type", {
      value: payload.type,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "uuid", {
      value: payload.uuid,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "state", {
      value: payload.state,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    return descriptions.concat([newObject]);
  } else {
    let newObject = descriptions.find((elem) => elem.uuid === payload.uuid);
    let newDescriptions = descriptions.filter(
      (elem) => elem.uuid !== payload.uuid
    );
    Object.defineProperty(newObject, payload.key, {
      value: payload.value,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "lang", {
      value: payload.lang,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "type", {
      value: payload.type,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "uuid", {
      value: payload.uuid,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "state", {
      value: payload.state,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    newDescriptions.push(newObject);
    return newDescriptions;
  }
};

const setStores = (stores, payload) => {
  let search = stores.find((elem) => elem.code === payload.code);
  if (!search) {
    let newObject = {};
    Object.defineProperty(newObject, "link", {
      value: payload.link,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "code", {
      value: payload.code,
      writable: true,
      configurable: true,
      enumerable: true,
    });

    return stores.concat([newObject]);
  } else {
    let newObject = stores.find((elem) => elem.code === payload.code);
    let newStores = stores.filter((elem) => elem.code !== payload.code);
    if (!payload.link) {
      return newStores;
    } else {
      Object.defineProperty(newObject, "link", {
        value: payload.link,
        writable: true,
        configurable: true,
        enumerable: true,
      });
      Object.defineProperty(newObject, "code", {
        value: payload.code,
        writable: true,
        configurable: true,
        enumerable: true,
      });
      newStores.push(newObject);
      return newStores;
    }
  }
};

const setSlugs = (slugs, payload) => {
  let search = slugs.find((elem) => elem.lang === payload.lang);
  if (!search) {
    let newObject = {};
    Object.defineProperty(newObject, payload.key, {
      value: payload.value,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "slug", {
      value: payload.slug,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "lang", {
      value: payload.lang,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    return slugs.concat([newObject]);
  } else {
    let newObject = slugs.find((elem) => elem.lang === payload.lang);
    let newSlugs = slugs.filter((elem) => elem.lang !== payload.lang);
    Object.defineProperty(newObject, payload.key, {
      value: payload.value,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "slug", {
      value: payload.slug,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "lang", {
      value: payload.lang,
      writable: true,
      configurable: true,
      enumerable: true,
    });

    newSlugs.push(newObject);
    return newSlugs;
  }
};
