import React from "react";
import { BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
import loadable from "@loadable/component";

import PrivateRoute from "./components/auth/PrivateRoute";
import GuestRoute from "./components/auth/GuestRoute";

const Login = loadable(() => import("./views/Login/Login"));
const Home = loadable(() => import("./views/Home/Home"));
const WineList = loadable(() => import("./views/Wines/WineList/WineList"));
// const CargaAdmin2 = loadable(() =>
//   import("./views/ABMDatosVino/VistaCargaDeDatos")
// );
const WineCreate = loadable(() =>
  import("./views/Wines/WineCreate/WineCreate")
);
const WineEdit = loadable(() =>
  import("./views/Wines/WineEdit/WineEdit")
);

const Lines = loadable(() => import("./views/Lines/Lines"));
const Labels = loadable(() => import("./views/Labels/Labels"));
const Files = loadable(() => import("./views/Files/Files"));
const News = loadable(() => import("./views/News/NewsList/News"))
const NewsCreate = loadable(() => import("./views/News/NewsCreate/CreateNews"))
const NewsEdit = loadable(() =>
  import("./views/News/NewsEdit/EditNews")
);
const Categories = loadable(() => import("./views/Categories/Categories"))
const Newsletter = loadable(() => import("./views/Newsletter/Newsletter"))

function App() {
  return (
    <Router>
      <Switch>
        <GuestRoute exact path="/login" component={Login} />
        {/* vinos */}
        <PrivateRoute exact path="/" component={Home} />
        <PrivateRoute exact path="/wines" component={WineList} />
        <PrivateRoute exact path="/wines/create" component={WineCreate} />
        <PrivateRoute exact path="/wines/edit/:wine_id" component={WineEdit} /> 
        {/*  <PrivateRoute exact path="/cargaDatos2" component={CargaAdmin2} />
        {/* marcas 
        <PrivateRoute exact path="/lines" component={Lines} />
        {/* marcas */}
        <PrivateRoute exact path="/lines" component={Lines} />
        {/* etiquetas */}
        <PrivateRoute exact path="/sub-lines" component={Labels} />
        {/* noticias */}
        <PrivateRoute exact path="/news" component={News} />
        <PrivateRoute exact path="/news/create" component={NewsCreate} />
        <PrivateRoute exact path="/news/edit/:notice_id" component={NewsEdit} /> 
        {/* categories */}      
        <PrivateRoute exact path="/categories" component={Categories} />
        {/* turismo */}
        <PrivateRoute exact path="/files" component={Files} />
        <PrivateRoute exact path="/newsletter" component={Newsletter} />

        <Redirect to="/" />
        {/* si el path no hace match con alguna de las rutas anteriores lo redirige al home */}
      </Switch>
    </Router>
  );
}

export default App;
