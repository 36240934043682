import {
  LIST_SET_LANG,
  LIST_SELECT_LANG,
  LIST_SET_LINES,
  LIST_SELECT_LINE,
  ERROR,
  LIST_SET_WINES,
  LIST_DELETE_WINE,
} from "../constant/types";

const initialState = {
  lang: [],
  selectLang: null,
  lines: [],
  selectLine: [],
  wines: [],
  loading: true,
};

export default function listWines(state = initialState, action) {
  switch (action.type) {
    case LIST_SET_LANG:
      return {
        ...state,
        lang: action.payload,
      };
    case LIST_SELECT_LANG:
      return {
        ...state,
        selectLang: action.payload,
      };
    case LIST_SET_LINES:
      return {
        ...state,
        lines: action.payload,
      };
    case LIST_SELECT_LINE:
      return {
        ...state,
        selectLine: state.lines.find((elem) => elem.line_id === action.payload),
        filterLabels: state.labels.filter(
          (elem) =>
            elem.line_id === action.payload &&
            elem.language_id === state.selectLang
        ),
      };
    case LIST_SET_WINES:
      return {
        ...state,
        wines: action.payload,
      };
    case LIST_DELETE_WINE:
      return {
        ...state,
        wines: state.wines.filter((elem) => elem.wine_id !== action.payload),
      };
    case ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
}
