import {
  LIST_LABELS_SET_LANGUAGES,
  LIST_LABELS_SET_LINES,
  LIST_LABELS_SET_LABELS,
  LIST_LABELS_SET_LANG_LABELS,
  ERROR,
  LIST_LABELS_SELECT_LINE,
  LIST_LABELS_SELECT_ORDER,
  LIST_LABELS_SELECT_LANG,
  CREATE_LABEL_SET_CHECKED,
  LIST_LABELS_DELETE,
  LIST_LABELS_RESET,
} from "../constant/types";

const initialState = {
  lines: [],
  selectLine: null,
  label: null,
  labels: [],
  filterLabels: null,
  selectLang: null,
  languages: [],
  langLabels: [],
  selectOrder: null,
  loading: true,
};

export default function listWines(state = initialState, action) {
  switch (action.type) {
    case LIST_LABELS_SET_LANGUAGES:
      return {
        ...state,
        languages: action.payload,
      };
    case CREATE_LABEL_SET_CHECKED:
      return {
        ...state,
        checked: action.payload.checked,
        label: state.labels.filter(
          (elem) => elem.label_id === action.payload.label_id
        ),
      };
    case LIST_LABELS_SELECT_LANG:
      return {
        ...state,
        selectLang: action.payload,
      };
    case LIST_LABELS_SET_LINES:
      return {
        ...state,
        lines: action.payload,
      };
    case LIST_LABELS_SET_LABELS:
      return {
        ...state,
        labels: action.payload,
      };
    case LIST_LABELS_DELETE:
      return {
        ...state,
        labels: state.labels.filter((elem) => elem.label_id !== action.payload),
      };
    case LIST_LABELS_SELECT_LINE:
      return {
        ...state,
        selectLine: state.lines.find((elem) => elem.line_id === action.payload),
        filterLabels: state.labels.filter(
          (elem) =>
            elem.line_id === action.payload &&
            elem.language_id === state.selectLang
        ),
      };
    case LIST_LABELS_SET_LANG_LABELS:
      return {
        ...state,
        langLabels: setLabels(state.langLabels, action.payload),
      };
    case LIST_LABELS_SELECT_ORDER:
      return {
        ...state,
        selectOrder: action.payload,
      };
    case LIST_LABELS_RESET:
      return {
        ...state,
        checked: false,
        selectLine: null,
        langLabels: [],
        filterLabels: null,
        label: null,
        selectOrder: null,
      };

    case ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
}

const setLabels = (langLabels, payload) => {
  let search = langLabels.find((elem) => elem.uuid === payload.uuid);
  if (!search) {
    let newObject = {};
    Object.defineProperty(newObject, "name", {
      value: payload.value,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "lang", {
      value: payload.lang,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "uuid", {
      value: payload.uuid,
      writable: true,
      configurable: true,
      enumerable: true,
    });

    return langLabels.concat([newObject]);
  } else {
    let newObject = langLabels.find((elem) => elem.uuid === payload.uuid);
    let newDescriptions = langLabels.filter(
      (elem) => elem.uuid !== payload.uuid
    );
    Object.defineProperty(newObject, "name", {
      value: payload.value,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "lang", {
      value: payload.lang,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "uuid", {
      value: payload.uuid,
      writable: true,
      configurable: true,
      enumerable: true,
    });

    newDescriptions.push(newObject);
    return newDescriptions;
  }
};
