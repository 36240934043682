import {
  ERROR,
  LIST_PDFS,
  LIST_PDFS_SELECT_LANG,
  LIST_PDFS_SET_LANGUAGES,
} from "../constant/types";

const initialState = {
  languages: [],
  selectLang: null,
  pdfs: [
    {
      lang_id: null,
      proposal: null,
      proposal_hands: null,
      menu: null,
      score: null,
      menu_crios: null,
      booking_crios: null,
      booking_osadia: null,
      booking_proposal: null,
      booking_proposal_hands: null,
      sustainability_report: null,
    },
  ],
  loading: true,
};

export default function listPDFs(state = initialState, action) {
  switch (action.type) {
    case LIST_PDFS_SET_LANGUAGES:
      return {
        ...state,
        languages: action.payload,
      };
    case LIST_PDFS_SELECT_LANG:
      return {
        ...state,
        selectLang: action.payload,
      };
    case LIST_PDFS:
      return {
        ...state,
        pdfs: action.payload,
      };
    case ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
}
