import { combineReducers } from "redux";
import AuthReducer from "./AuthReducer";
import CreateWinesReducer from "./CreateWinesReducer";
import ListWinesReducer from "./ListWinesReducer";
import ListLinesReducer from "./ListLinesReducer";
import EditWineReducer from "./EditWineReducer";
import ListLabelsReducer from "./ListLabelsReducer";
import ListPDFsReducer from "./ListPDFsReducer";
import ListNewsReducer from "./ListNewsReducer";
import CreateNewsReducer from "./CreateNewsReducer";
import EditNewReducer from "./EditNewReducer";
import ListCategoriesReducer from "./ListCategoriesReducer";

export default combineReducers({
  AuthReducer: AuthReducer,
  CreateWinesReducer: CreateWinesReducer,
  CreateNewsReducer: CreateNewsReducer,
  EditNewReducer: EditNewReducer,
  EditWineReducer: EditWineReducer,
  ListCategoriesReducer: ListCategoriesReducer,
  ListWinesReducer: ListWinesReducer,
  ListNewsReducer: ListNewsReducer,
  ListLinesReducer: ListLinesReducer,
  ListLabelsReducer:ListLabelsReducer,
  ListPDFsReducer:ListPDFsReducer,
});
