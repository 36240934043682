import {
  CREATE_NEWS_ADD_CATEGORY,
  CREATE_NEWS_REMOVE_CATEGORY,
  CREATE_NEWS_REMOVE_DESCRIPTION,
  CREATE_NEWS_SELECT_LANG,
  CREATE_NEWS_SELECT_STATE,
  CREATE_NEWS_SET_CATEGORIES,
  CREATE_NEWS_SET_DATE,
  CREATE_NEWS_SET_DESCRIPTIONS,
  CREATE_NEWS_SET_IMAGE,
  CREATE_NEWS_SET_LANG,
  CREATE_NEWS_SET_SLUG,
  ERROR,
} from "../constant/types";

const initialState = {
  lang: [],
  selectLang: null,
  date: null,
  descriptions: [], //{title,description,language,uuid}
  image: null,
  loading: true,
  categories: [
    { category_id: "1234567", title: "categoria 1" },
    { category_id: "876543", title: "categoria 2" },
  ],
  selectCategories: [],
  selectedState: null,
};

export default function createNews(state = initialState, action) {
  switch (action.type) {
    case CREATE_NEWS_SET_LANG:
      return {
        ...state,
        lang: action.payload,
      };
    case CREATE_NEWS_SELECT_STATE:
      return {
        ...state,
        selectedState: action.payload,
      };
    case CREATE_NEWS_SET_DATE:
      return {
        ...state,
        date: action.payload,
      };
    case CREATE_NEWS_SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case CREATE_NEWS_SELECT_LANG:
      return {
        ...state,
        selectLang: action.payload,
      };
    case CREATE_NEWS_SET_DESCRIPTIONS:
      return {
        ...state,
        descriptions: setDescription(state.descriptions, action.payload),
      };
    case CREATE_NEWS_SET_SLUG:
      return {
        ...state,
        descriptions: setDescriptionSlug(state.descriptions, action.payload),
      };
    case CREATE_NEWS_REMOVE_DESCRIPTION:
      return {
        ...state,
        descriptions: removeDescription(state.descriptions, action.payload),
      };
    case CREATE_NEWS_SET_IMAGE:
      return {
        ...state,
        image: action.payload,
      };
    case CREATE_NEWS_ADD_CATEGORY:
      return {
        ...state,
        selectCategories: addCategories(state.selectCategories, action.payload),
      };
    case CREATE_NEWS_REMOVE_CATEGORY:
      return {
        ...state,
        selectCategories: removeCategories(
          state.selectCategories,
          action.payload
        ),
      };
    case ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
}

const addCategories = (selectCategories, category_id) => {
  let newSelectCategories = selectCategories.concat([category_id]);
  return newSelectCategories;
};
const removeCategories = (selectCategories, category_id) => {
  let newSelectCategories = selectCategories.filter(
    (elem) => elem !== category_id
  );
  return newSelectCategories;
};

const setDescription = (descriptions, payload) => {
  let search = descriptions.find((elem) => elem.uuid === payload.uuid);
  if (!search) {
    let newObject = {};
    Object.defineProperty(newObject, payload.key, {
      value: payload.value,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "lang", {
      value: payload.lang.code,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "uuid", {
      value: payload.uuid,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "type", {
      value: payload.type,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    if (payload.type === "paragraph")
      Object.defineProperty(newObject, "order", {
        value: payload.order,
        writable: true,
        configurable: true,
        enumerable: true,
      });
    return descriptions.concat([newObject]);
  } else {
    let newObject = descriptions.find((elem) => elem.uuid === payload.uuid);
    let newDescriptions = descriptions.filter(
      (elem) => elem.uuid !== payload.uuid
    );
    if (!payload.value) {
      return newDescriptions;
    }
    Object.defineProperty(newObject, payload.key, {
      value: payload.value,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "lang", {
      value: payload.lang.code,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "uuid", {
      value: payload.uuid,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "type", {
      value: payload.type,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    if (payload.type === "paragraph")
      Object.defineProperty(newObject, "order", {
        value: payload.order,
        writable: true,
        configurable: true,
        enumerable: true,
      });
    newDescriptions.push(newObject);
    return newDescriptions;
  }
};

const setDescriptionSlug = (descriptions, payload) => {
  let search = descriptions.find((elem) => elem.type === payload.type);
  if (!search) {
    let newObject = {};
    Object.defineProperty(newObject, payload.key, {
      value: payload.value,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "lang", {
      value: payload.lang.code,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "uuid", {
      value: payload.uuid,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "type", {
      value: payload.type,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    return descriptions.concat([newObject]);
  } else {
    let newObject = descriptions.find((elem) => elem.uuid === payload.uuid);
    let newDescriptions = descriptions.filter(
      (elem) => elem.uuid !== payload.uuid
    );
    if (!payload.value) {
      return newDescriptions;
    }
    Object.defineProperty(newObject, payload.key, {
      value: payload.value,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "lang", {
      value: payload.lang.code,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "uuid", {
      value: payload.uuid,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    Object.defineProperty(newObject, "type", {
      value: payload.type,
      writable: true,
      configurable: true,
      enumerable: true,
    });
    newDescriptions.push(newObject);
    return newDescriptions;
  }
};
const removeDescription = (descriptions, payload) => {
  let newDescriptions = descriptions.filter(
    (elem) => elem.uuid !== payload.uuid
  );
  newDescriptions.map(
    (description) =>
      (description.order =
        description.type === "paragraph"
          ? description.order >= payload.order
            ? description.order - 1
            : description.order
          : null)
  );
  return newDescriptions;
};
// const setFile = (descriptions, payload) => {
//   let search = descriptions.find((elem) => elem.uuid === payload.uuid);
//   if (!search) {
//     let newObject = {};
//     Object.defineProperty(newObject, payload.key, {
//       value: payload.value,
//       writable: true,
//       configurable: true,
//       enumerable: true,
//     });
//     Object.defineProperty(newObject, "lang", {
//       value: payload.lang,
//       writable: true,
//       configurable: true,
//       enumerable: true,
//     });
//     Object.defineProperty(newObject, "uuid", {
//       value: payload.uuid,
//       writable: true,
//       configurable: true,
//       enumerable: true,
//     });

//     return descriptions.concat([newObject]);
//   } else {
//     let newObject = descriptions.find((elem) => elem.uuid === payload.uuid);
//     let newDescriptions = descriptions.filter(
//       (elem) => elem.uuid !== payload.uuid
//     );
//     Object.defineProperty(newObject, payload.key, {
//       value: payload.value,
//       writable: true,
//       configurable: true,
//       enumerable: true,
//     });
//     Object.defineProperty(newObject, "lang", {
//       value: payload.lang,
//       writable: true,
//       configurable: true,
//       enumerable: true,
//     });
//     Object.defineProperty(newObject, "uuid", {
//       value: payload.uuid,
//       writable: true,
//       configurable: true,
//       enumerable: true,
//     });

//     newDescriptions.push(newObject);
//     return newDescriptions;
//   }
// };
